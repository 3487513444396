<template>
  <v-row
    class="ma-0"
    style="max-width:100%"
  >
    <v-col
      class="pa-0"
      style="max-width:100%"
    >
      <v-card
        outlined
        class="pt-6"
        style="max-width:100%"
      >
        <v-data-table
          ref="table"
          :value="selectedValue"
          :headers="headers"
          :items="leads"
          :loading="loading"
          :loading-text="$t('leads.table.loading-text')"
          :no-data-text="noDataText"
          :footer-props="{
            itemsPerPageOptions,
            disableItemsPerPage: false,
            disablePagination: false,
            showCurrentPage: true,
            showFirstLastPage: true,
            'items-per-page-text': $t('leads.table.items-per-page'),
          }"
          :items-per-page="listOptions.itemsPerPage"
          :show-select="showSelect"
          :item-class="getRowClass"
          :server-items-length="serverItemsLength"
          :options.sync="listOptions.options"
          :height="listHeight"
          fixed-header
          @click:row="handleRowClick"
          @input="selectItems"
          @toggle-select-all="selectAllVisibleItems"
        >
          <!-- Headers -->
          <!-- eslint-disable vue/valid-v-slot -->
          <template v-slot:header.assignedUser>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <span v-on="on">{{ $t('leads.table.labels.employee') }}</span>
              </template>
              <span>{{ $t('leads.table.actions.assigned-user') }}</span>
            </v-tooltip>
          </template>

          <template v-slot:header.city>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <span v-on="on">{{ $t('leads.table.labels.city') }}</span>
              </template>
              <span>{{ $t('leads.table.actions.city') }}</span>
            </v-tooltip>
          </template>

          <template v-slot:header.zip>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <span v-on="on">{{ $t('leads.table.labels.zip') }}</span>
              </template>
              <span>{{ $t('leads.table.actions.zip') }}</span>
            </v-tooltip>
          </template>

          <template v-slot:header.data-table-select="{ on, props }">
            <v-simple-checkbox
              color="primary"
              v-bind="props"
              :ripple="false"
              v-on="on"
            />
          </template>

          <!-- Items -->
          <template v-slot:item.data-table-select="{ item, isSelected, select }">
            <v-simple-checkbox
              v-if="!item.invitationBlockedReason"
              :ripple="false"
              :value="isSelected"
              @input="select($event)"
            />
            <v-tooltip
              v-else
              top
            >
              <template v-slot:activator="{ on }">
                <v-icon v-on="on">
                  mdi-alert-circle-check-outline
                </v-icon>
              </template>
              <span>{{ readableInvitationBlockedReason(item) }}</span>
            </v-tooltip>
          </template>

          <template v-slot:item.exportedAt="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <div
                  style="min-width: 80px"
                  v-on="on"
                >
                  <v-icon v-if="item.exportedAt">
                    mdi-check
                  </v-icon>
                </div>
              </template>
              <span v-if="item.exportedAt">{{ formatDate(item.exportedAt) }}</span>
            </v-tooltip>
          </template>

          <template v-slot:item.createdAt="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <div
                  style="min-width:60px"
                  v-on="on"
                >
                  <span>{{ formatDate(item.createdAt, 'dateShort') }}</span>
                </div>
              </template>
              <span>{{ formatDate(item.createdAt) }}</span>
            </v-tooltip>
          </template>

          <template v-slot:item.lastConversionAt="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <div
                  style="min-width:100px"
                  v-on="on"
                >
                  <span>{{ formatDate(item.lastConversionAt, 'dateShort') }}</span>
                </div>
              </template>
              <span>{{ formatDate(item.lastConversionAt) }}</span>
            </v-tooltip>
          </template>

          <template v-slot:item.name="{ item }">
            <router-link
              :to="{ name: 'lead-details', params: { id: item.id } }"
              class="d-flex h-full align-center"
              @click="handleNameClick($event)"
            >
              <template v-if="hasName(item)">
                {{ formatName(item) }}
              </template>
              <template v-else>
                {{ item.email }}
              </template>
            </router-link>
          </template>

          <template v-slot:item.conversions="{ item }">
            <v-row no-gutters>
              <v-col
                cols="12"
                lg="12"
                md="8"
                sm="12"
                class="d-flex flex-column flex-sm-row flex-wrap align-end"
              >
                <!-- Conversion-Types -->
                <div
                  v-for="{ type, label, color } of conversionTypes"
                  :key="type"
                >
                  <v-tooltip
                    top
                  >
                    <template
                      v-slot:activator="{ on }"
                    >
                      <v-chip
                        v-if="showAction(item, type)"
                        :color="isPropertyRequestUrgent(item,type) ? 'red lighten-4' : color"
                        x-small
                        class="ml-1"
                        v-on="isPropertyRequestUrgent(item,type) ? on : {}"
                      >
                        {{ $t(label) }}
                      </v-chip>
                    </template>
                    <span>{{ isPropertyRequestUrgent(item,type) ? $t('leads.table.tooltips.start-asap') :'' }}</span>
                  </v-tooltip>
                </div>

                <!-- Postal Shipping -->
                <div
                  v-if="isColumnVisible('conversions.download.postalShipping')"
                >
                  <v-chip
                    v-if="showPostalShippingAction(item)"
                    color="amber lighten-4"
                    x-small
                    class="ml-1"
                  >
                    {{ $t('leads.table.actions.postal-shipping') }}
                  </v-chip>
                </div>

                <div
                  v-if="isColumnVisible('conversionCountByType.DOWNLOAD')"
                >
                  <v-chip
                    v-for="(amount, type) in getDownloadConversions(item)"
                    :key="type"
                    :color="DOWNLOAD_TYPES[type].color"
                    x-small
                    class="ml-1"
                  >
                    {{ $t(DOWNLOAD_TYPES[type].label) }}
                  </v-chip>
                </div>

                <!-- Quiz PreConversion -->
                <div
                  v-if="isColumnVisible('conversions.preConversion.type.QUIZ')"
                >
                  <v-chip
                    v-if="showQuizPreConversionAction(item)"
                    color="lime lighten-4"
                    x-small
                    class="ml-1"
                  >
                    {{ $t('leads.table.actions.quiz-finished') }}
                  </v-chip>
                </div>
              </v-col>
              <v-spacer />
            </v-row>
          </template>

          <template v-slot:item.currentStatus="{ item }">
            {{ $t(leadStatus[item.currentStatus]) }}
          </template>

          <template v-slot:item.assignedUser="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <div
                  style="min-width: 80px"
                  v-on="on"
                >
                  <v-avatar
                    v-if="item.assignedUser"
                    color="grey"
                    size="30"
                  >
                    <span class="white--text caption font-weight-bold">
                      {{ getInitials(item.assignedUser) }}
                    </span>
                  </v-avatar>
                </div>
              </template>
              <span v-if="item.assignedUser">{{ formatName(item.assignedUser) }}</span>
            </v-tooltip>
          </template>

          <template v-slot:item.confirmedAt="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-icon
                  v-if="item.confirmedAt"
                  color="success"
                  v-on="on"
                >
                  mdi-check-circle
                </v-icon>
                <v-icon
                  v-else
                  color="orange lighten-2"
                  v-on="on"
                >
                  mdi-clock
                </v-icon>
              </template>
              <span v-if="item.confirmedAt">{{ $t('leads.table.labels.confirmed') }}</span>
              <span v-else>{{ $t('leads.table.labels.not-confirmed') }}</span>
            </v-tooltip>
          </template>

          <template v-slot:item.email="{ item }">
            <v-tooltip
              top
            >
              <template v-slot:activator="{ on }">
                <span v-on="on">
                  <span
                    class="d-inline-block text-truncate"
                    style="max-width: 150px;"
                  >{{ item.email }}</span>
                </span>
              </template>
              {{ item.email }}
            </v-tooltip>
          </template>

          <template v-slot:item.city="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <div
                  class="text-truncate"
                  style="max-width: 120px"
                  v-on="on"
                >
                  {{ formatCity(item) }}
                </div>
              </template>
              <span>{{ formatCity(item) }}</span>
            </v-tooltip>
          </template>

          <template v-slot:item.zip="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <div
                  class="text-truncate"
                  style="min-width: 50px; max-width: 120px"
                  v-on="on"
                >
                  {{ formatZip(item) }}
                </div>
              </template>
              <span>{{ formatZip(item) }}</span>
            </v-tooltip>
          </template>

          <template v-slot:footer.page-text="items">
            {{ items.pageStart }} - {{ items.pageStop }} {{ $t('leads.table.of') }} {{ items.itemsLength }}
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import currencyMixin from '@/mixins/currency'
import status from '@/lib/leadStatus'
import { DEFAULT_HEADERS } from '../configs'
import brandingMixin from '@/mixins/branding'
import AvailableStartDate from '@/lib/enums/propertyRequest/AvailableStartDate'
import { ReadableInvitationBlockedReason } from '../enums/Invitation'

const ITEMS_PER_PAGE_OPTIONS = [20, 40, 80]
const LIST_HEIGHT_SETTINGS = {
  xs: 'calc(100vh - 360px)',
  sm: 'calc(100vh - 390px)',
  md: 'calc(100vh - 390px)',
  lg: 'calc(100vh - 330px)',
  xl: 'calc(100vh - 330px)'
}

export default {
  mixins: [currencyMixin, brandingMixin],

  props: {
    leads: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    },
    showSelect: {
      type: Boolean,
      default: false
    },
    disableFilters: {
      type: Boolean,
      default: false
    },
    serverItemsLength: {
      type: Number,
      default: 0
    },
    listOptions: {
      type: Object,
      required: true
    },
    columnSettings: {
      type: Array,
      required: true
    },
    selectItems: {
      type: Function,
      default: () => {}
    },
    activeFiltersCount: {
      type: Number,
      default: 0
    },
    triggerSelectAllItems: {
      type: Boolean,
      default: false
    },
    triggerDeselectAllItems: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      selectedValue: []
    }
  },

  computed: {
    listHeight () {
      return LIST_HEIGHT_SETTINGS[this.$vuetify.breakpoint.name]
    },
    headers () {
      const translatedHeader = DEFAULT_HEADERS.map((col) => ({
        ...col,
        text: this.$t(col.text)
      }))

      return [
        ...this.columnSettings,
        // Actions must be visible regardless of settings
        { value: 'actions', visible: true }
      ]
        .filter(({ visible }) => visible)
        .filter(({ hideDisplay }) => !hideDisplay)
        .map(({ value }) => translatedHeader.find((col) => col.value === value))
    },

    noDataText () {
      return this.activeFiltersCount > 0
        ? this.$t('leads.table.no-data-texts.0')
        : this.$t('leads.table.no-data-texts.1')
    },

    conversionTypes () {
      return Object.values(this.CONVERSION_TYPES).filter(({ type }) => this.isColumnVisible(`conversionCountByType.${type}`))
    }
  },

  watch: {
    triggerSelectAllItems () {
      if (this.triggerSelectAllItems) {
        this.selectAllItems()
      }
    },
    triggerDeselectAllItems () {
      if (this.triggerDeselectAllItems) {
        this.deselectAllItems()
      }
    }
  },

  created () {
    this.leadStatus = status
    this.itemsPerPageOptions = ITEMS_PER_PAGE_OPTIONS
    this.CONVERSION_TYPES = {
      VALUATION: {
        type: 'VALUATION',
        label: 'leads.table.actions.valuation',
        color: 'blue lighten-4'
      },
      PLOT_VALUATION: {
        type: 'PLOT_VALUATION',
        label: 'leads.table.actions.plot-valuation',
        color: 'purple lighten-4'
      },
      RETURN_CALL: {
        type: 'RETURN_CALL',
        label: 'leads.table.actions.return-call',
        color: 'teal lighten-4'
      },
      LIFE_ANNUITY: {
        type: 'LIFE_ANNUITY',
        label: 'leads.table.actions.life-annuity',
        color: 'cyan lighten-4'
      },
      PARTIAL_SALE: {
        type: 'PARTIAL_SALE',
        label: 'leads.table.actions.partial-sale',
        color: 'lime lighten-4'
      },
      PROPERTY_REQUEST: {
        type: 'PROPERTY_REQUEST',
        label: 'leads.table.actions.property-request',
        color: 'brown lighten-4'
      }
    }
    this.DOWNLOAD_TYPES = {
      GUIDE: {
        label: 'leads.table.actions.download.guide',
        color: 'deep-purple lighten-4'
      },
      CHECKLIST: {
        label: 'leads.table.actions.download.checklist',
        color: 'blue-grey lighten-4'
      }
    }
  },

  methods: {
    readableInvitationBlockedReason (item) {
      return ReadableInvitationBlockedReason[item.invitationBlockedReason]
    },
    getValuationCount (item) {
      return item?.conversionCountByType?.VALUATION + item?.conversionCountByType?.IN_APP_VALUATION
    },
    showAction (item, type) {
      return item?.conversionCountByType && item?.conversionCountByType[type] > 0
    },
    showPostalShippingAction (item) {
      return Boolean(
        item.conversions &&
          item.conversions.find(
            (current) => current.download?.postalShipping && current.download.postalShipping !== 'NONE'
          )
      )
    },
    showQuizPreConversionAction (item) {
      return Boolean(item.conversions?.some(({ preConversion }) => preConversion?.type === 'QUIZ'))
    },

    getDownloadConversions (item) {
      const downloads = item.conversions?.filter(({ type }) => type === 'DOWNLOAD')
      const downloadsByType = downloads.reduce((acc, current) => {
        if (!current.landingpage?.type) return acc
        if (acc[current.landingpage?.type]) {
          acc[current.landingpage.type]++
        } else {
          acc[current.landingpage.type] = 1
        }
        return acc
      }, {})

      return downloadsByType
    },

    formatCity (item) {
      const valuationConversion = item.conversions.find((conversion) => conversion.type === 'VALUATION')
      if (valuationConversion) {
        return valuationConversion.valuation.inputs.address.city
      }
      return ''
    },
    formatZip (item) {
      const valuationConversion = item.conversions.find((conversion) => conversion.type === 'VALUATION')
      if (valuationConversion) {
        return valuationConversion.valuation.inputs.address.zip
      }
      return ''
    },
    hasName ({ firstname, lastname }) {
      return Boolean(firstname || lastname)
    },
    formatName ({ firstname, lastname }) {
      if (!firstname || !lastname) {
        return ''
      }
      return `${firstname} ${lastname}`
    },
    getInitials ({ firstname, lastname }) {
      return [firstname, lastname]
        .map((str) => (typeof str === 'string' ? str.charAt(0) : ''))
        .join('')
        .toUpperCase()
    },
    getRowClass (item) {
      return {
        'lead-list__item': true,
        'lead-list__item--new': this.$vuetify.breakpoint.mdAndUp && item.currentStatus === 'new',
        'lead-list__item-mobile--new': this.$vuetify.breakpoint.smAndDown && item.currentStatus === 'new'
      }
    },
    isColumnVisible (key) {
      return this.columnSettings.find((setting) => setting.value === key)?.visible
    },
    isPropertyRequestUrgent (lead, type) {
      return Boolean(
        lead.conversions &&
        type === 'PROPERTY_REQUEST' &&
        lead.conversions.find(
          (current) => current.propertyRequest?.availableStartDate && current.propertyRequest.availableStartDate === AvailableStartDate.EARLIEST
        ) &&
        lead.currentStatus === 'new'
      )
    },
    selectAllVisibleItems ({ items = [], value: checked }) {
      this.$emit('allVisibleItemsSelected', { items, checked })
    },
    selectAllItems () {
      this.$refs.table.toggleSelectAll(true)
      this.$emit('allItemsSelected', true)
    },
    deselectAllItems () {
      this.$refs.table.toggleSelectAll(false)
      this.selectedValue = []
      this.$emit('allItemsSelected', false)
    },

    /**
     * We simulate normal link behavior when using cmd/ctrl + click
     * to open the details in a new tab.
     */
    handleRowClick (item, _, event) {
      const href = this.$router.resolve({
        name: 'lead-details',
        params: { id: item.id }
      }).href

      if (event.metaKey || event.ctrlKey) {
        window.open(href, '_blank')
      } else {
        this.$router.push(href)
      }
    },

    /**
     * When clicking on the link we prevent the default row click behavior.
     */
    handleNameClick (event) {
      if (event.metaKey || event.ctrlKey) {
        event.stopPropagation()
      } else {
        event.preventDefault()
      }
    }
  }
}
</script>

<style scoped>
.action-row {
  width: 800px;
}

@media (max-width: 600px) {
  .action-row {
    width: 200px;
  }
}
</style>

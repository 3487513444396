var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"lead-list",style:(_vm.cssProps)},[_c('Toolbar',{attrs:{"active-filters-count":_vm.activeFiltersCount,"list-options":_vm.listOptions,"refetch-parent":_vm.refetchParent},on:{"exportLeads":_vm.downloadLeads,"showImport":function($event){_vm.showContactImportDialog=true},"toggleLeadListTool":_vm.toggleLeadListTool}}),_c('div',{staticClass:"d-flex",class:{'flex-column': _vm.$vuetify.breakpoint.smAndDown},style:({'gap': _vm.isAdditionalFilterOpen ? '20px' : _vm.hasActiveLeadListTool ? '10px' : '0px'})},[_c('v-slide-x-transition',[(_vm.hasActiveLeadListTool)?_c('div',{staticClass:"d-flex",class:{
          'list-tool-width':_vm.$vuetify.breakpoint.mdAndUp && !_vm.isAdditionalFilterOpen,
          'list-tool-double-width':_vm.$vuetify.breakpoint.mdAndUp && _vm.isAdditionalFilterOpen,
          'w-full mb-10':_vm.$vuetify.breakpoint.smAndDown
        },style:({
          'gap': _vm.isAdditionalFilterOpen ? '10px' : '0px',
          'max-height': _vm.$vuetify.breakpoint.lgAndDown ? 'calc(100vh - 245px)' : '1150px'
        })},[(_vm.leadListOptionDetails.invitation.isOpen)?_c('BulkInvitation',{staticClass:"h-full",class:{
            'list-tool-width':_vm.$vuetify.breakpoint.mdAndUp,
            'w-full':_vm.$vuetify.breakpoint.smAndDown
          },staticStyle:{"overflow":"scroll"},attrs:{"selected-lead-ids":_vm.selectedLeadIds,"has-crm":_vm.isCrmActive,"crm-name":_vm.readableCrmName,"has-applicable-leads":_vm.hasLeads},on:{"close":function($event){return _vm.toggleLeadListTool('invitation')},"updateFetchIdsParameters":_vm.updateFetchIdsParameters,"enableImport":function($event){_vm.showContactImportDialog=true}}},[_c('template',{slot:"selectAll"},[_c('v-card',{staticStyle:{"border-radius":"10px !important"},attrs:{"outlined":""}},[_c('v-card-text',{staticClass:"pa-2"},[(!_vm.isSelectAllLeadsVisible)?_c('div',[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('lead-list.some-contacts-selected', { amount: _vm.selectedItemsLength }))}})]):(_vm.isAllLeadsSelected)?_c('div',[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('lead-list.all-contacts-selected', { amount: _vm.selectedItemsLength }))}}),_c('v-btn',{staticClass:"w-full mt-2",attrs:{"depressed":"","text":"","small":"","color":"primary"},on:{"click":_vm.deselectAllItems}},[_vm._v(" "+_vm._s(_vm.$t('buttons.lead-list.deselect'))+" ")])],1):_c('div',[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('lead-list.some-contacts-selected', { amount: _vm.selectedItemsLength }))}}),_c('v-btn',{staticClass:"w-full mt-2",attrs:{"depressed":"","text":"","small":"","color":"primary","loading":_vm.isAllLeadsLoading,"disabled":_vm.isAllLeadsLoading},on:{"click":_vm.selectAllItems}},[_vm._v(" "+_vm._s(_vm.$t('buttons.lead-list.select-filtered'))+" ")])],1)])],1)],1)],2):_vm._e(),(_vm.leadListOptionDetails.deleteLeads.isOpen)?_c('DeleteLeads',{staticClass:"h-full",class:{
            'list-tool-width':_vm.$vuetify.breakpoint.mdAndUp,
            'w-full':_vm.$vuetify.breakpoint.smAndDown
          },staticStyle:{"overflow":"scroll"},attrs:{"selected-items-length":_vm.selectedItemsLength,"trigger-reset-delete-leads":_vm.triggerResetDeleteLeads,"is-deleting-leads":_vm.isDeletingLeads,"has-crm":_vm.isCrmActive,"crm-name":_vm.readableCrmName},on:{"triggeredResetDeleteLeads":_vm.triggeredResetDeleteLeads,"close":function($event){return _vm.toggleLeadListTool('deleteLeads')},"updateFetchIdsParameters":_vm.updateFetchIdsParameters,"deleteLeads":_vm.deleteLeads}},[_c('template',{slot:"selectAll"},[_c('v-card',{staticStyle:{"border-radius":"10px !important"},attrs:{"outlined":""}},[_c('v-card-text',{staticClass:"pa-2"},[(!_vm.isSelectAllLeadsVisible)?_c('div',[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('lead-list.some-contacts-selected', { amount: _vm.selectedItemsLength }))}})]):(_vm.isAllLeadsSelected)?_c('div',[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('lead-list.all-contacts-selected', { amount: _vm.selectedItemsLength }))}}),_c('v-btn',{staticClass:"w-full mt-2",attrs:{"depressed":"","text":"","small":"","color":"primary"},on:{"click":_vm.deselectAllItems}},[_vm._v(" "+_vm._s(_vm.$t('buttons.lead-list.deselect'))+" ")])],1):_c('div',[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('lead-list.some-contacts-selected', { amount: _vm.selectedItemsLength }))}}),_c('v-btn',{staticClass:"w-full mt-2",attrs:{"depressed":"","text":"","small":"","color":"primary","loading":_vm.isAllLeadsLoading,"disabled":_vm.isAllLeadsLoading},on:{"click":_vm.selectAllItems}},[_vm._v(" "+_vm._s(_vm.$t('buttons.lead-list.select-filtered'))+" ")])],1)])],1)],1)],2):_vm._e(),(_vm.leadListOptionDetails.assignLeads.isOpen)?_c('AssignLeads',{staticClass:"h-full",class:{
            'list-tool-width':_vm.$vuetify.breakpoint.mdAndUp,
            'w-full':_vm.$vuetify.breakpoint.smAndDown
          },staticStyle:{"overflow":"scroll"},attrs:{"selected-items-length":_vm.selectedItemsLength,"is-assigning-leads":_vm.isAssigningLeads,"has-crm":_vm.isCrmActive,"crm-name":_vm.readableCrmName,"trigger-reset-assign-leads":_vm.triggerResetAssignLeads},on:{"close":function($event){return _vm.toggleLeadListTool('assignLeads')},"updateFetchIdsParameters":_vm.updateFetchIdsParameters,"assignLeads":_vm.assignLeads}},[_c('template',{slot:"selectAll"},[_c('v-card',{staticStyle:{"border-radius":"10px !important"},attrs:{"outlined":""}},[_c('v-card-text',{staticClass:"pa-2"},[(!_vm.isSelectAllLeadsVisible)?_c('div',[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('lead-list.some-contacts-selected', { amount: _vm.selectedItemsLength }))}})]):(_vm.isAllLeadsSelected)?_c('div',[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('lead-list.all-contacts-selected', { amount: _vm.selectedItemsLength }))}}),_c('v-btn',{staticClass:"w-full mt-2",attrs:{"depressed":"","text":"","small":"","color":"primary"},on:{"click":_vm.deselectAllItems}},[_vm._v(" "+_vm._s(_vm.$t('buttons.lead-list.deselect'))+" ")])],1):_c('div',[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('lead-list.some-contacts-selected', { amount: _vm.selectedItemsLength }))}}),_c('v-btn',{staticClass:"w-full mt-2",attrs:{"depressed":"","text":"","small":"","color":"primary","loading":_vm.isAllLeadsLoading,"disabled":_vm.isAllLeadsLoading},on:{"click":_vm.selectAllItems}},[_vm._v(" "+_vm._s(_vm.$t('buttons.lead-list.select-filtered'))+" ")])],1)])],1)],1)],2):_vm._e(),(_vm.leadListOptionDetails.filter.isOpen)?_c('FilterSettings',{staticClass:"h-full",class:{
            'list-tool-width':_vm.$vuetify.breakpoint.mdAndUp,
            'w-full':_vm.$vuetify.breakpoint.smAndDown
          },attrs:{"has-tool":_vm.hasTool,"can-close":_vm.leadListOptionDetails.filter.isOpen && !_vm.leadListOptionDetails.invitation.isOpen,"filter-button-label":"Weitere Filter","has-crm":_vm.isCrmActive,"crm-name":_vm.readableCrmName},on:{"filtersChanged":_vm.onFiltersChanged,"close":function($event){return _vm.toggleLeadListTool('filter')}}}):_vm._e(),(_vm.leadListOptionDetails.column.isOpen)?_c('ColumnSettingsTool',{staticClass:"h-full",class:{
            'list-tool-width':_vm.$vuetify.breakpoint.mdAndUp,
            'w-full':_vm.$vuetify.breakpoint.smAndDown
          },attrs:{"has-tool":_vm.hasTool},on:{"columnSettingsChanged":_vm.onColumnSettingsChanged,"close":function($event){return _vm.toggleLeadListTool('column')}}}):_vm._e()],1):_vm._e()]),_c('List',{class:{
        'list-width':_vm.$vuetify.breakpoint.mdAndUp && _vm.hasActiveLeadListTool && !_vm.isAdditionalFilterOpen,
        'list-double-width':_vm.$vuetify.breakpoint.mdAndUp && _vm.isAdditionalFilterOpen,
        'w-full':_vm.$vuetify.breakpoint.smAndDown
      },attrs:{"leads":_vm.leads,"show-select":_vm.showListSelect,"loading":_vm.loading,"server-items-length":_vm.serverItemsLength,"select-items":_vm.selectListItems,"trigger-select-all-items":_vm.triggerSelectAllItems,"trigger-deselect-all-items":_vm.triggerDeselectAllItems,"list-options":_vm.listOptions,"active-filters-count":_vm.activeFiltersCount,"column-settings":_vm.columnSettings},on:{"allVisibleItemsSelected":_vm.allVisibleItemsSelected,"allItemsSelected":_vm.allItemsSelected}})],1),_c('import',{attrs:{"refetch-parent":_vm.refetchParent,"show-import-dialog":_vm.showContactImportDialog},on:{"cancel":function($event){_vm.showContactImportDialog=false}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }